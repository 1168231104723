var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DAV-workshop__trunk"
  }, [_c('component-title', {
    attrs: {
      "color": "green"
    },
    on: {
      "goPrev": _vm.goPrev
    }
  }), _c('div', {
    staticClass: "DAV-blockClass",
    class: {
      'DAV-blockClass--option-mode': this.$route.path === '/option'
    }
  }, [_c('div', {
    staticClass: "multiple-img-wrap"
  }, [_c('div', {
    staticClass: "frmBlock__title"
  }, [_vm._v("내용 입력")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.text,
      expression: "data.text"
    }],
    staticClass: "frmBlock__textField multiple-textarea",
    attrs: {
      "id": "textarea",
      "type": "text",
      "placeholder": "내용을 입력해주세요."
    },
    domProps: {
      "value": _vm.data.text
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.data, "text", $event.target.value);
      }, _vm.changeInput]
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "frmBlock__title frmBlock__title--last"
  }, [_vm._v("이미지 업로드")]), _c('div', {
    staticClass: "frmBlock__layout"
  }, [_c('ImagePicker', {
    ref: "imagePicker",
    attrs: {
      "index": _vm.getPopup.index,
      "value": _vm.getPopup.value.img,
      "option": _vm.getPopup.option
    }
  })], 1), _c('div', {
    staticClass: "frmBlock__title frmBlock__title--last"
  }, [_vm._v(" 이미지 설명(alt) ")]), _c('div', {
    staticClass: "frmBlock__layout"
  }, [_c('TextField', {
    attrs: {
      "placeholder": "이미지 설명을 입력해주세요."
    },
    on: {
      "input": function input(v) {
        return _vm.updateValue('alt', v);
      }
    },
    model: {
      value: _vm.data.alt,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "alt", $$v);
      },
      expression: "data.alt"
    }
  })], 1), _c('div', {
    staticClass: "frmBlock__title frmBlock__title--last"
  }, [_vm._v(" URL "), _c('tooltip', {
    attrs: {
      "top": "30px",
      "text": "\uB3C4\uBA54\uC778\uC774 \uD3EC\uD568\uB41C URL\uC744 \uC785\uB825\uD558\uB294 \uACBD\uC6B0 https:// \uB610\uB294 http:// \uB97C \uD568\uAED8 \uC785\uB825\uD574\uC8FC\uC138\uC694.<br/>\n          \uFF65 \uC608) https://qpicki.com"
    }
  })], 1), _c('div', {
    staticClass: "frmBlock__layout frmBlock__layout--grow"
  }, [_c('TextField', {
    attrs: {
      "placeholder": "URL을 입력해주세요.",
      "value": _vm.data.url
    },
    on: {
      "input": function input(v) {
        return _vm.updateValue('url', v);
      }
    }
  }), _c('Dropdown', {
    attrs: {
      "value": _vm.data.target
    },
    on: {
      "updateTarget": function updateTarget(v) {
        return _vm.updateValue('target', v);
      }
    }
  })], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }